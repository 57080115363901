<template>
    <div
        class="fixed flex items-center justify-center popup"
        v-if="showPopup"
        @touchmove.stop.prevent="moveHandle"
    >
        <div class="duration-500 mask" :class="conetentFlag ? 'show_mask' : ''" @click="closePopup"></div>
        <div
            class="relative duration-700 rounded popup_content"
            :class="conetentFlag ? 'show_conent' : 'hidden_content'"
        >
            <div>
                <img src="../../../assets/images/signin /signin1.png" alt class="sign_in_img" />
            </div>
            <div class="absolute colse top-3 right-3" @click="closePopup">
                <img src="../../../assets/images/signin /signin2.png" style="width:24px" alt />
            </div>
            <div class="absolute w-full px-4 top-40">
                <div class="w-full px-2 py-3 bg-white rounded-md">
                    <div class="text-base font-bold title">已连续签到{{ totalDays }}天</div>
                    <div class="mt-2 text-sm text-gray-500">连续签到{{ rules.length }}天有可分享积分翻倍</div>
                    <div class="mt-3">
                        <div
                            class="w-full rounded-full outer_box"
                            style="height:10px;background: #FFEAAB;"
                        >
                            <div
                                class="h-full rounded-full inner"
                                style="width: 50%;background: linear-gradient(90deg, #FFDD72 0%, #FF8956 100%);"
                                :style="{ width: calculateWidth() }"
                            ></div>
                        </div>
                        <div class="flex items-center justify-between mt-1">
                            <div
                                v-for="(item,index) in rules"
                                :key="index"
                                class="text-xs text-gray-500"
                            >{{ item.fewDays }}天</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="absolute flex justify-center w-full px-20 btn_box bottom-4">
                <div class="w-full py-2 text-lg font-bold text-white btn" @click="signIn($event)">签到</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showPopup: false,// 控组组件的显示隐藏
            conetentFlag: false,  // 控制mask的变量
            days: ["1天", "2天", "3天", "4天", "5天", "6天", "7天"],
            rules: [],
            totalDays: 0, // 总签到数
        }
    },
    created() {
        // console.log(this.isShow)
        // this.showPopup = this.isShow
        // this.signInInfo()
        this.signRule()
    },
    watch: {
        // isShow: {
        //     handler: function (newVal) {
        //         alert(`监听到了isShow的改变${newVal}`)
        //         if (newVal) {
        //             this.showPopup = newVal
        //             setTimeout(() => {
        //                 this.conetentFlag = newVal
        //             }, 100)
        //         } else {
        //             this.conetentFlag = newVal
        //             setTimeout(() => {
        //                 this.showPopup = newVal
        //             }, 500)
        //         }
        //     },
        //     deep: true
        // }
    },
    methods: {
        closePopup() {
            this.$emit('close')
        },
        moveHandle() {
            return false
        },
        // 签到
        signIn() {
            // let className = e.target.className
            // alert(JSON.stringify(className))
            this.$request({
                method: "post",
                url: this.$requestPath.signIn,
                data: {
                    ceshi: "测试"
                }
            }).then(res => {
                // console.log("签到", res)
                // alert(JSON.stringify(res))
                if (res.code == 0) {
                    this.$EventBus.$emit("toast", { type: 'success', message: "签到成功" })
                    this.totalDays = this.totalDays + 1
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("签到错误", error)
            })
        },
        // 获取签到信息
        signInInfo() {
            this.$request({
                methods: "get",
                url: this.$requestPath.signInInfo,
                data: {}
            }).then(res => {
                // console.log("获取签到信息", res)
                if (res.code == 0) {
                    this.totalDays = res.data.totalDays
                    // this.signIn()
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("获取签到信息错误", error)
            })
        },

        // 获取签到规则
        signRule() {
            this.$request({
                methods: "get",
                url: this.$requestPath.signRule,
                data: {}
            }).then(res => {
                // console.log("获取签到规则", res)
                if (res.code == 0) {
                    this.rules = []
                    this.rules.push(...res.data)

                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("获取签到规则错误", error)
            })
        },
        // 计算签到条的长度
        calculateWidth() {
            let width = null
            if (this.totalDays == 1) {
                width = [this.totalDays / (this.rules.length - 1) * 100]
                return width / 2 + '%'
            } else if (this.totalDays == 0) {
                return 0
            } else {
                width = [(this.totalDays - 1) / (this.rules.length - 1) * 100]
                return width + '%'
            }

        },
        showFunc() {
            this.showPopup = true
            setTimeout(() => {
                this.conetentFlag = true
            }, 100)
        },
        closeFunc() {
            this.conetentFlag = false
            setTimeout(() => {
                this.showPopup = false
            }, 500)
        }

    },

}
</script>

<style lang="scss" scoped>
.popup {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
}
.mask {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
}
.show_mask {
    background: rgba(0, 0, 0, 0.3);
}
.popup_content {
    // height: 300px;
    width: 86%;
    max-width: 56.25rem;
    // background: rgba(255, 255, 255, 0.7);
    // box-shadow: 0 1px 5px 0 rgba(31, 38, 135, 0.37);
    // backdrop-filter: blur(13.5px);
    // -webkit-backdrop-filter: blur(16.5px);
    // border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    z-index: 10;
    transform: scale(0.3);
    opacity: 0;
}
.show_conent {
    transform: scale(1);
    opacity: 1;
}
.btn {
    background: linear-gradient(180deg, #ffdc06 0%, #ff8721 100%);
    box-shadow: 0px 13px 13px 7px #ed8510;
    border-radius: 49px;
}
</style>

<template>
    <div class="flex justify-center home_box">
        <div class="home_inner_box">
            <div class="relative flex justify-center w-full swiper">
                <!-- style="background-image: url('../../../assets/images/index/pc/indexpc12.png');" -->
                <div class="w-full carousel_box" style="overflow: hidden;">
                    <el-carousel arrow="always" :height="bannerHeight + 'px'" :interval="5000" ref="carousel"
                        @click.native="linkTo" :indicator-position="indicatorPosition">
                        <el-carousel-item v-for="(item, index) in banner" :key="index" class="cursor-pointer">
                            <!-- <h3 class="small">{{ item }}</h3> -->
                            <!-- <img src="../../../assets/images/index/pc/indexpc12.png" alt /> -->
                            <img :src="item.picUrl"
                                :style="{ height: bannerHeight + 'px', width: '100%', 'object-fit': 'cover' }" alt />
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <!-- <div class="absolute z-0 banner_bg"></div> -->
            </div>
            <!-- <img src="../../../assets/images/index/pc/indexpc12.png" alt=""> -->
            <div class="flex justify-center w-full my-2 sm:my-8">
                <div class="w-full content_box">
                    <div class="flex justify-between tabs" v-if="hiddenFlag">
                        <div @click="changeTab(index)" :class="tab == index ? 'bg-primary text-white' : ''"
                            class="flex items-center justify-center cursor-pointer" style="width:33.33%"
                            v-for="(item, index) in  tabs" :key="index">{{ item }}</div>
                    </div>
                    <div class="w-full mt-3 mb-1" v-if="hiddenFlag">
                        <div class="flex flex-wrap w-full pc_show">
                            <div class="flex cursor-pointer top_img" v-for="(item, index) in products" :key="index"
                                :class="item.class" @click="goProduct(item)">
                                <div class="top_img_box">
                                    <img :src="item.picUrl" alt />
                                    <!-- <div class="content">
                                    <div
                                        class="text-white duration-500 content_title"
                                    >{{ item.title }}</div>
                                    <div
                                        class="my-2 text-white duration-500 content_desc"
                                    >{{ item.desc }}</div>
                                    <div
                                        class="font-bold text-left text-primary content_desc"
                                    >{{ item.price }}元</div>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex-wrap phone_show">
                            <div class="flex mb-1 top_img" @click="goProduct(item)" v-for="(item, index) in products"
                                :key="index">
                                <div class="top_img_box">
                                    <img :src="item.picUrl" alt />
                                    <!-- <div class="content">
                                    <div
                                        class="text-white duration-500 content_title"
                                    >{{ phoneTopImages[0].title }}</div>
                                    <div
                                        class="my-2 text-white duration-500 content_desc"
                                    >{{ phoneTopImages[0].desc }}</div>
                                    <div
                                        class="font-bold text-left text-primary content_desc"
                                    >{{ phoneTopImages[0].price }}元</div>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="normalProducts.length > 0">
                        <div class="flex items-center justify-between w-full middle_img">
                            <div class="cursor-pointer middle_left" @click="goProduct(normalProducts[0])">
                                <!-- <img src="../../../assets/images/index/pc/indexpc9.png" alt /> -->
                                <img :src="normalProducts[0].picUrl" alt v-if="normalProducts[0]" />
                            </div>
                            <div class="h-full middle_right">
                                <div class="cursor-pointer middle_right_img" @click="goProduct(normalProducts[1])">
                                    <!-- <img src="../../../assets/images/index/pc/indexpc11.png" alt /> -->
                                    <img :src="normalProducts[1].picUrl" alt v-if="normalProducts[1]" />
                                </div>
                                <div class="cursor-pointer middle_bottom" @click="goProduct(normalProducts[2])">
                                    <!-- <img src="../../../assets/images/index/pc/indexpc10.png" alt /> -->
                                    <img :src="normalProducts[2].picUrl" alt v-if="normalProducts[2]" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full mt-2 sm:mt-3" v-if="normalProducts.length > 0 && normalProducts[3]"
                        @click="goProduct(normalProducts[3])">
                        <!-- <img class="w-full" src="../../../assets/images/index/pc/indexpc5.png" alt /> -->
                        <img class="w-full" :src="normalProducts[3].picUrl" alt />
                    </div>
                    <div class="flex items-center justify-between text_out_box" v-if="hiddenFlag">
                        <div class>笔记本</div>
                        <div class="flex-auto text-left text_box">
                            <span class="px-6 cursor-pointer" v-for="(item, index) in classification" :key="index"
                                :class="{ 'text-primary': select == index }" @click="changeComputer(index)">{{
                                    item.categoryName
                                }}</span>
                        </div>
                        <div class="cursor-pointer pc_show" @click="goMore">更多 ></div>
                    </div>
                    <div class="w-full my-3" v-if="bottomImages.length > 0 && hiddenFlag">
                        <!-- <img class="w-full" src="../../../assets/images/index/pc/indexpc6.png" alt /> -->
                        <img class="w-full" :src="bottomImages[0].posterUrl" alt />
                    </div>
                    <div class="flex flex-wrap w-full" v-if="hiddenFlag">
                        <div class="bottom_img" v-for="(item, index) in bottomImages" :key="index"
                            :style="{ display: index > 0 ? 'flex' : 'none' }"
                            :class="index == 2 ? 'justify-center' : index == 3 ? 'justify-end' : ''">
                            <div class="bottom_img_box">
                                <img :src="item.posterUrl" alt />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <img src="https://t7.baidu.com/it/u=3281686603,2838365105&fm=193&f=GIF" alt /> -->
        <SignIn :isShow="isShow" @close="closeSearch" ref="signIn"></SignIn>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SignIn from "../components/SignIn.vue"
export default {
    data() {
        return {
            tab: 0,
            tabs: ['新品预约', '特惠秒杀', '人气爆款'],
            topImages: [
                // { image: require("../../../assets/images/index/pc/indexpc4.png"), url: "", title: "2021款 小新Air 14 英特尔酷睿i5", desc: "第11代英特尔酷睿i5-1155G7/Windows10家庭版", price: '5000', class: "" },
                // { image: require("../../../assets/images/index/pc/indexpc4.png"), url: "", title: "2021款 小新Air 14 英特尔酷睿i5", desc: "第11代英特尔酷睿i5-1155G7/Windows10家庭版", price: '5000', class: "justify-center" },
                // { image: require("../../../assets/images/index/pc/indexpc4.png"), url: "", title: "2021款 小新Air 14 英特尔酷睿i5", desc: "第11代英特尔酷睿i5-1155G7/Windows10家庭版", price: '5000', class: "justify-end" },
            ],
            phoneTopImages: [
                // { image: require("../../../assets/images/index/phone/top_img.png"), url: "", title: "2021款 小新Air 14 英特尔酷睿i5", desc: "第11代英特尔酷睿i5-1155G7/Windows10家庭版", price: '5000', class: "" },
            ],
            // 底部商品
            bottomImages: [
                // { posterUrl: require("../../../assets/images/index/pc/indexpc7.png") },
                // { posterUrl: require("../../../assets/images/index/pc/indexpc7.png") },
                // { posterUrl: require("../../../assets/images/index/pc/indexpc7.png") },
                // { posterUrl: require("../../../assets/images/index/pc/indexpc7.png") },
            ],
            computer: [
                // { name: "T5", class: false },
                // { name: "T7", class: true },
                // { name: "T9", class: false }
            ],
            select: 0,
            // 图片父容器高度
            bannerHeight: 1000,
            // 浏览器宽度
            screenWidth: 0,
            pageType: "",
            community: [
                // { icon: require('../../../assets/images/index/pc/indexpc8.png'), title: "公众号" },
                // { icon: require('../../../assets/images/index/pc/indexpc8.png'), title: "B站UP号" },
                // { icon: require('../../../assets/images/index/pc/indexpc8.png'), title: "官方贴吧" },
                // { icon: require('../../../assets/images/index/pc/indexpc8.png'), title: "官方Q群" },
            ],
            // 获取所有商品
            productList: [
                // { icon: require('../../../assets/images/index/pc/indexpc8.png'), title: "笔记本" },
                // { icon: require('../../../assets/images/index/pc/indexpc8.png'), title: "笔记本" },
                // { icon: require('../../../assets/images/index/pc/indexpc8.png'), title: "笔记本" },

            ],
            banner: [],
            products: [], // 获取活动商品海报
            normalProducts: [], // 获取非活动商品海报
            classification: [], //  获取的二级分类
            hiddenFlag: false,
            isShow: false, //  控制签到组件的显示和隐藏
            indicatorPosition: "outside",// 轮播图指示灯位置
            ifSignIn: false,// 用户今日是否签到
            code: "", // 微信或者微信公众号授权获取的code
        };
    },

    created() {
        this.getBanner()
        // this.findPoster()  // 获取活动海报
        this.findPoster("normal") // 获取非活动的海报
        if (this.getPrimaryClassification.length > 0) {
            let { categoryId, coding } = this.getPrimaryClassification[0]
            this.getClassification(categoryId, coding)
        }



        // 对返回的code进行处理
        var params = this.getUrlParam("code");
        this.code = params

        if (this.judgeWx() && !this.getLoginStatus && this.code == null) {
            // alert("执行了")
            // setTimeout(() => {
            this.$store.commit("accountAuthorization", "/index")
            // }, 2000);
        }


        if (params && params != '' && !this.getLoginStatus) {
            // console.log("获取到code", this.$route.query.code)
            // 判断打开页面的设备是PC还是移动端
            if (this.browserRedirect()) {
                // let url = window.location.href
                // this.$EventBus.$emit("toast", { type: 'error', message: 'url=' + url })
                // this.$EventBus.$emit("toast", { type: 'error', message: 'code=' + params })
                this.$store.dispatch("wechatLogin", { code: params, loginType: 4 })
            } else {
                // this.wechatLogin()
                this.$store.dispatch("wechatLogin", { code: params, loginType: 3 })
            }

        }

        this.$EventBus.$on("showSignIn", () => {
            // this.ifSignInFunc()
            // this.$refs.signIn.signInInfo()
            // this.isShow = true
        })

    },
    watch: {
        tab: function () { // 监听活动类型的切换
            this.findPoster()
        },
        getPrimaryClassification: function () { // 监听一级分类的获取
            let { categoryId, coding } = this.getPrimaryClassification[0]
            this.getClassification(categoryId, coding)
        },
        isShow: function (newVal) {
            if (newVal) {
                this.$refs.signIn.showFunc()
            } else {
                this.$refs.signIn.closeFunc()
            }
        }
    },
    mounted() {
        this.isShow = false
        // 首次加载时,需要调用一次 (内容部分的最大宽度是1200,所以当屏幕大小超过1200时也将当做1200计算)
        this.screenWidth = window.innerWidth;
        this.setSize();
        // 窗口大小发生改变时,调用一次
        window.onresize = () => {
            this.screenWidth = window.innerWidth;
            this.setSize();
        }
        let isMobile = localStorage.getItem("isMobile")
        if (isMobile == 'true') {
            this.indicatorPosition = "none"

        }

        // if (this.judgeWx() && this.getLoginStatus) {
        //     this.ifSignInFunc()

        // }

    },
    components: {
        SignIn
    },
    computed: {
        ...mapGetters(['getPrimaryClassification', 'getLoginStatus'])
    },
    methods: {
        changeTab(index) {
            this.tab = index
        },
        changeComputer(index) {
            this.select = index
            this.getCategoryProduct(this.classification[index].categoryId)
        },
        goActivity() {
            this.$router.push({ path: "/index/activity" })
        },
        setSize: function () {
            let screenWidth = 0
            this.screenWidth > 1920 ? screenWidth = 1920 : screenWidth = this.screenWidth
            // 通过浏览器宽度(图片宽度)计算高度
            if (this.screenWidth > 680) {
                this.bannerHeight = 680 / 1920 * screenWidth;
            } else {
                this.bannerHeight = 920 / 1920 * screenWidth;
            }
        },
        // 获取海报
        findPoster(type) {
            let url = this.$requestPath.findPoster
            if (!type) {
                url = url + `?type=${this.tab + 1}`
            }
            this.$request({
                methods: "get",
                url,
                data: {}
            }).then(res => {
                // console.log("获取海报", res, type)
                if (res.code == 0) {
                    if (type) {
                        this.normalProducts = []
                        res.data.forEach(item => {
                            this.normalProducts.push(item)
                        })

                        // console.log("normalProducts", this.normalProducts)
                    }
                    // else {
                    //     this.products = []
                    //     this.products.push(...res.data)

                    // }
                }
            }).catch(error => {
                console.log("获取的海报", error)
            })
        },
        //  获取轮播图
        getBanner() {
            this.$request({
                methods: "get",
                url: this.$requestPath.getBanner,
                data: {}
            }).then(res => {
                // console.log("获取的轮播图", res)
                if (res.code == 0) {
                    this.banner = []
                    this.banner.push(...res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("获取轮播图", error)
            })
        },
        // 商品链接跳转
        goProduct(item) {
            if (item.ifStart) {
                if (item.ifLink) {
                    window.open(item.linkUrl)
                } else {
                    this.$router.push({
                        path: "/index/activity", query: {
                            pageType: "poster",
                            posterId: item.posterId
                        }
                    })
                }

            } else {
                // this.$EventBus.$emit("toast", { type: 'error', message: '商品链接未开启，敬请期待' })
            }
        },
        // 获取二级分类
        getClassification(parentId, code) {
            let url = this.$requestPath.getCommodityClassify + `?parentId=${parentId}&code=${code}`
            this.$request({
                method: "get",
                url
            }).then(res => {
                // console.log('获取二级分类', res)
                if (res.code == 0) {
                    this.classification = []
                    this.classification.push(...res.data)
                    if (this.classification.length > 0) {
                        this.getCategoryProduct(this.classification[0].categoryId)
                    } else {
                        this.porducts = []
                    }
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '获取二级分类失败' })
                }
            }).catch(error => {
                console.log('获取二级分类错误', error)
            })
        },
        //  获取分类商品
        getCategoryProduct(classifyId) {
            let url = this.$requestPath.getClassifyProducts + `?classifyId=${classifyId}`
            this.$request({
                method: "get",
                url
            }).then(res => {
                // console.log('获取分类商品', res)
                if (res.code == 0) {
                    // this.porducts = []
                    // this.porducts.push(...res.data)
                    let list = this.bottomImages
                    this.bottomImages = []
                    let newList = [...res.data, ...list].splice(0, 4)
                    // console.log(newList)
                    this.bottomImages.push(...newList)
                    // this.menu = []
                    // this.menu.push(...res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '获取商品失败' })
                }

            }).catch(error => {
                console.log('获取商品错误', error)
            })
        },
        goMore() {
            let { categoryId, coding } = this.getPrimaryClassification[0]
            this.$router.push({ path: `/index/common?code=${coding}&parentId=${categoryId}` })
        },
        linkTo() {
            let activeIndex = this.$refs.carousel.activeIndex
            window.open(this.banner[activeIndex].linkUrl)
        },
        // 关闭签到组件的函数
        closeSearch() {
            this.isShow = false
        },
        // 查看用户今日是否签到
        ifSignInFunc() {
            this.$request({
                method: "get",
                url: this.$requestPath.ifSignIn
            }).then(res => {
                if (res.code == 0) {
                    this.ifSignIn = res.data
                    if (!this.ifSignIn) {
                        this.$refs.signIn.signInInfo()
                        this.isShow = true
                    }
                } else {
                    this.ifSignIn = false
                }
                // if (!this.ifSignIn) {
                //     this.isShow = true
                // }
            }).catch(error => {
                console.log('是否签到', error)
            })
        }
    },
};
</script>

<style lang='scss' scoped>
.home_box {
    min-height: var(--min-height);
}

.home_inner_box {
    width: 100%;
    max-width: 1920px;
}

.product_list {
    // max-width: var(--max-width);
}

.swiper {
    // height: 18.75rem;
    // background: red;

    overflow: hidden;
}

.banner_bg {
    background-image: url("../../../assets/images/index/pc/indexpc12.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background-size: 100% 100%;
    filter: blur(30px);
}

.carousel_box {
    // padding-left: 0.9375rem;
    // padding-right: 0.9375rem;
    // max-width: var(--max-width);
}

.middle_left {
    width: 49%;
    padding-bottom: 44%;
    height: 0;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.middle_right {
    width: 49%;
    height: 0;
    padding-bottom: 44%;
    position: relative;

    img {
        width: 100%;
    }

    .middle_right_img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 49%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .middle_bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 48%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.bottom_img {
    width: 33.33%;
}

.bottom_img_box {
    width: 98%;
}

.text_box {
    user-select: none;
}

@media screen and (max-width: 640px) {
    .content_box {
        padding-left: 0.3125rem;
        padding-right: 0.3125rem;
        // max-width: var(--max-width);
    }

    .pc_show {
        display: none;
    }

    .phone_show {
        display: flex;
    }

    .tabs {
        height: 1.875rem;
        background: #f0f0f0;
    }

    .top_img {
        width: 100%;
        // margin-right: 0.65%;
        // padding-bottom: 3.125rem;
        height: 9.375rem;
        overflow: hidden;

        .top_img_box {
            width: 100%;
            // margin-right: 0.65%;
            // padding-bottom: 98%;
            height: 100%;
            position: relative;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // border-radius: 4px;
            position: absolute;
            top: 0;
            left: 0;
        }

        .content {
            width: 50%;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }

    .content_title {
        font-size: 0.875rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .content_desc {
        text-align: left;
        font-size: 0.75rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .text_out_box {
        font-size: 0.875rem;
    }

    .text_box {
        padding-left: 2.5rem;
    }
}

@media screen and (min-width: 640px) {
    .content_box {
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        // max-width: var(--max-width);
    }

    .tabs {
        height: 3.125rem;
        background: #f0f0f0;
    }

    .pc_show {
        display: flex;
    }

    .phone_show {
        display: none;
    }

    .top_img {
        width: 33.33%;
        // margin-right: 0.65%;
        padding-bottom: 33.33%;
        height: 0;

        .top_img_box {
            width: 98%;
            margin-left: 1%;
            padding-bottom: 98%;
            height: 0;
            position: relative;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // border-radius: 4px;
            position: absolute;
            top: 0;
            left: 0;
        }

        .content {
            width: 50%;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }

    .content_title {
        font-size: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .content_desc {
        text-align: left;
        font-size: 0.875rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .text_out_box {
        font-size: 1rem;
    }

    .text_box {
        padding-left: 25%;
    }
}
</style>